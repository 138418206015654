import React, { useContext, useEffect } from 'react';
// import { SocketContext } from './SocketContext';
import { SOCK_POINT } from 'src/module/config';
import socketIOClient from 'socket.io-client';
import { GetToken } from 'src/helpers/request';
// import { SocketContext } from 'src/module/socket';

export const SocketContext = React.createContext(null)


export const useSocketProvider = () => useContext(SocketContext)

const SocketProviders = ({ children }) => {
  const token = GetToken();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const socket = socketIOClient(SOCK_POINT, {
    autoConnect: false,
    auth: {
      ...(token && { Authorization: 'Bearer ' + token?.token }),
      MyIdReseller: params?.id_reseller,
    },
    transportOptions: {
      polling: {
        extraHeaders: {
          ...(token && { Authorization: 'Bearer ' + token?.token }),
        },
      },
    },
  });

  // useEffect(() => {
  //   socket.connect();

  //   return () => {
  //     socket.disconnect(); // Bersihkan koneksi saat komponen dilepas
  //   };
  // }, [socket]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProviders;
